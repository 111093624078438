@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Padyakke+Expanded+One&family=Source+Sans+Pro:wght@200&display=swap');
@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@100&family=Zen+Kurenaido&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Josefin+Slab:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Jost:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Pompiere&display=swap');
/* Second in */
@import url('https://fonts.googleapis.com/css2?family=Lancelot&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

/* .active {
    @apply lg: text-slate-500 text-red-600
} */

@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@100&family=Zen+Kurenaido&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200&family=Padyakke+Expanded+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Palanquin:wght@100&family=Zen+Kurenaido&display=swap');

.main {
    font-family: 'Pompiere', cursive;
}

.instagarm {
    background: #f09433;
    background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
    background: -webkit-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
    background: linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f09433', endColorstr='#bc1888', GradientType=1);
}

.logo {
    width: 150px;
    height: 55px;
    aspect-ratio: 3/2;
    object-fit: contain;
    mix-blend-mode: color-burn;
}